import Link from "next/link"
import { useTranslations } from "use-intl"
import { Section } from "../Templates"

function TopLinksColumn({ heading, which, t }) {
  return (
    <div className="flex-1 first:mt-0 mt-16 md:mt-0">
      <h2 className="font-heading-xl font-medium text-gray-800 leading-10">
        {heading}
      </h2>
      <ul className="divide-y-2 divide-gray-200 divide-solid">
        {[1, 2, 3, 4, 5].map((linkNo) => (
          <li key={`which-${linkNo}`} className="py-4">
            <Link href={t(`${which}.link${linkNo}_url`)}>
              <span className="text-dolBlue hover:underline">
                {t(`${which}.link${linkNo}_text`)}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export function TopLinks() {
  const t = useTranslations("Homepage.TopLinks")

  return (
    <Section>
      <div className="flex flex-col md:flex-row md:space-x-10">
        <TopLinksColumn heading={t("Crops.title")} which="Crops" t={t} />
        <TopLinksColumn heading={t("Seasonal.title")} which="Seasonal" t={t} />
        <TopLinksColumn
          heading={t("Locations.title")}
          which="Locations"
          t={t}
        />
      </div>
    </Section>
  )
}
