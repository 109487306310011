import { Alert } from "@trussworks/react-uswds"
import { JobPreviewCardSkeleton } from "components/Skeletons"
import { JobPreviewCard } from "components/JobSearch/JobPreviewCard"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ReactSwitch from "react-switch"
import useSWR from "swr"
import { useTranslations } from "use-intl"
import {
  appendLocale,
  cleanJobPreviewCards,
  fetchDatahubDocs,
  richResolver,
} from "utils"
import { Section } from "../Templates"

export function PopularListings() {
  const router = useRouter()
  const [useCurrentLocation, setUseCurrentLocation] = useState(true)
  const { coordinates, locationName } = useSelector((state) => state.user)
  const [cleanData, setCleanData] = useState(null)
  const t = useTranslations("Shared")
  const p = useTranslations("Homepage.Popular")

  const select = `&$select=case_number,job_title${appendLocale(
    router.locale
  )},begin_date,end_date,basic_rate_from,basic_rate_to,pay_range_desc,employer_trade_name,employer_business_name,worksite_city,worksite_state`

  const withCurrentLocationFilter =
    coordinates && useCurrentLocation
      ? `geo.distance(coord, geography'POINT(${coordinates.longitude} ${coordinates.latitude})') le 80.4672 and `
      : ""

  const { data, error } = useSWR(
    `&$top=6&$orderby=popularity desc&$filter=${withCurrentLocationFilter}active eq true and display eq true${select}`,
    fetchDatahubDocs
  )

  useEffect(() => {
    if (data) {
      const cleaned = data?.map((listing) =>
        cleanJobPreviewCards({
          listing,
          locale: router.locale,
          t,
        })
      )
      setCleanData(cleaned)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const PopularListingsHeading = () => {
    const onChangeCurrentLocation = () => setUseCurrentLocation((prev) => !prev)
    return (
      <div className="flex flex-col md:flex-row space-x-4 space-y-4 md:space-0 md:justify-between">
        <h2 className={`font-heading-xl font-medium text-gray-800`}>
          {coordinates && locationName && useCurrentLocation
            ? p.rich("title_local", { location: locationName, ...richResolver })
            : p.rich("title_nationwide", richResolver)}
        </h2>
        {coordinates && locationName ? (
          <div className="flex flex-row space-x-2 space-y-2 self-end md:items-center">
            <label htmlFor="popular-switch" className="text-gray-700">
              {p("toggle_label")}
            </label>
            <ReactSwitch
              id="popular-switch"
              onChange={onChangeCurrentLocation}
              checked={useCurrentLocation}
              onColor="#005ea2" // dolBlue
            />
          </div>
        ) : null}
      </div>
    )
  }

  const onChange = (case_number) => {
    router.push(encodeURI(`/jobs?search="${case_number}"`))
  }

  const renderPopularListings = () => {
    if (cleanData && cleanData.length > 0) {
      return cleanData.map((popularJob) => (
        <JobPreviewCard
          key={popularJob.case_number}
          onClick={() => onChange(popularJob.case_number)}
          {...popularJob}
        />
      ))
    } else if (cleanData && cleanData.length === 0) {
      return <p>{p("zero")}</p>
    }

    return [1, 2, 3, 4, 5, 6].map((no) => <JobPreviewCardSkeleton key={no} />)
  }

  return (
    <Section
      headingOverride={<PopularListingsHeading />}
      bg="gray-100"
      tw="pb-10 pt-10"
    >
      {error ? (
        <Alert type="error" className="mt-3">
          {p("error")}
        </Alert>
      ) : null}
      <div className="mt-6 flex flex-nowrap overflow-x-scroll space-x-4 md:space-x-0 md:grid md:grid-cols-2 xl:grid-cols-3 md:gap-4">
        {renderPopularListings()}
      </div>
    </Section>
  )
}
